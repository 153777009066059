.alert-danger {
  background: #dc35451a;
  border-radius: 2px;
  color: var(--rfx-danger);
  padding: 1.25rem;
  border: 0;
  a {
    color: var(--rfx-danger);
    text-decoration: underline;
    font-family: 'Roboto Regular';
  }
}
