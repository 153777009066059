/* You can add global styles to this file, and also import other style files */
:root {
  --rfx-default: #000;
  --rfx-white: #fff;
  --rfx-theme: #082b49;
  --rfx-theme-hover: #001426;
  --rfx-background: #eaeaea;
  --rfx-border: #c6c6c6;
  --rfx-primary: #00789c;
  --rfx-primary-hover: #007395;
  --rfx-primary-active: #006a8a;
  --rfx-secondary: #ffffff;
  --rfx-secondary-hover: #e5e5e5;
  --rfx-secondary-active: #d7d7d7;
  --rfx-danger: #dc3545;
  --rfx-danger-hover: #d2293a;
  --rfx-danger-active: #bd1e2d;
  --rfx-warning: #ffa14a;
  --rfx-small-text: #333333;
  --rfx-link: #007ea2;
  --rfx-disabled-link: #6e6e6e;
  --rfx-sidebar-width: 268px;
  --rfx-sidebar-width-collapsed: 54px;
  --rfx-placeholder: #666666;
    --rfx-sidebar-md-width: 200px;
}

@import './assets/styles/typography.scss';
@import '@fortawesome/fontawesome-free/css/all.css';
@import url('./assets/styles/overrides/bootstrap/_bootstrap.scss');
@import 'ngx-toastr/toastr';

.ngx-toastr {
  width: 450px !important;
}

.toast-container .ngx-toastr {
  color: black;
  border-radius: 8px;
  padding: 1.25rem 15px 1.25rem 5rem;
  background-position: 18px 15px;
  background-size: 40px;
  box-shadow: 0 0 5px #999999;
}
.toast-title {
  padding-bottom: 0.75rem;
}

.toast-error {
  background-color: #ffffff;
  background-image: url('/assets/svg/x-circle.svg');
}
.toast-info {
  background-color: #ffffff;
  background-image: url('/assets/svg/info.png');
}

.toast-message {
  color: #212121;
  padding-right: 2rem;
}

.toast-close-button {
  color: #797979;
  text-decoration: none;
  cursor: pointer;
  font-weight: 100;
  font-size: 28px;
  right: 0.25rem;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
.toast-message a {
  color: #212121;
  text-decoration: underline;
  font-weight: 100;
  font-family: 'Roboto Regular';
  font-size: 14px;
  &:hover {
    color: #212121;
    text-decoration: underline;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
app-icon.alert-error svg {
  fill: var(--rfx-danger);
  stroke: #fff;
}
body.modal-open {
  overflow: hidden;
}
.pagination-footer app-icon.chevron-left svg,
.pagination-footer app-icon.chevron-right svg {
  color: white;
}
.question-answer-container .accordion-button {
  background: none!important;
  color: #000!important;
  padding: 12px!important;
  &:focus {
    box-shadow: none;
  }
  &::after {
    background-image: url('/assets/svg/minus-circle.svg');
  }
  &:not(.collapsed) {
    box-shadow: none;
    &::after {
      background-image: url('/assets/svg/plus-circle.svg');
    }
  }
}
.question-answer-container .accordion-body {
  padding: 0 35px 10px 0.75rem;
  color: #444444;
}
.question-answer-container .accordion-item {
  background-color: #f5f5f5;
  border:none;
}
app-icon {
  &.error {
    svg {
      fill: var(--rfx-danger);
      stroke: #fff;
    }
  }
  &.warning {
    svg {
      fill: var(--rfx-warning);
      stroke: #fff;
    }
  }
  &.info {
    svg {
      fill: var(--rfx-primary-hover);
      stroke: #fff;
    }
  }
}
.tm-default-modal {
  .btn-close {
    --bs-btn-close-bg: url('/assets/svg/x.svg')  !important;
  }
  .custom-header {
     .btn-close {
       --bs-btn-close-bg: url('/assets/svg/x-black.png') !important;
     }
  }
}
app-icon.profile svg {
  // fill: var(--rfx-danger);
   stroke: gray;
 }

