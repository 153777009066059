.btn {
  height: 37px;
  border-radius: 3px;
  font-size: 16px;
  padding: 6px 13px;
  &.min-width {
    width: 125px;
  }
  &.btn-link {
    height: 33px;
    padding: 4px 12px;
    font-family: "Roboto Bold";
    text-decoration: none;
    color: var(--rfx-primary);
    &.min-width {
      width: 110px;
    }
    &:hover {
      color: var(--rfx-primary-hover) !important;
      text-decoration: underline;
    }
    &:active,
    &:focus-visible {
      color: var(--rfx-primary-active) !important;
      box-shadow: 0px 0px 0px 4px #007ea2b2 !important;
    }
    &:disabled {
      color: var(--rfx-disabled-link) !important;
      opacity: 0.65;
    }
  }
  &.btn-sm {
    height: 26px;
    font-size: 14px;
    padding: 2px 8px;
    &.min-width {
      width: 90px;
    }
  }
  &.btn-lg {
    height: 39px;
    font-size: 18px;
    padding: 6px 16px;
    &.min-width {
      width: 140px;
    }
  }
  &.btn-primary {
    background: var(--rfx-primary) !important;
    border: 1px solid var(--rfx-primary) !important;
    color: var(--rfx-white) !important;
    border-radius: 3px;
    &:hover {
      background: var(--rfx-primary-hover) !important;
      border: 1px solid var(--rfx-primary-hover) !important;
    }
    &:active,
    &:focus-visible {
      background: var(--rfx-primary-active) !important;
      border: 1px solid var(--rfx-primary-active) !important;
      box-shadow: 0px 0px 0px 4px #007ea2b2 !important;
    }
    &:disabled {
      background: #969696 !important;
      border: 1px solid #969696 !important;
      color: var(--rfx-white) !important;
      opacity: 0.65;
    }
    &.btn-theme {
      background: var(--rfx-theme) !important;
      border: 1px solid var(--rfx-theme) !important;
      &:hover {
        background: var(--rfx-theme-hover) !important;
        border: 1px solid var(--rfx-theme-hover) !important;
      }
      &:active,
      &:focus-visible {
        background: var(--rfx-theme-hover) !important;
        border: 1px solid var(--rfx-theme-hover) !important;
        box-shadow: 0px 0px 0px 4px #082b49d9  !important;
      }
      &:disabled {
        background: #969696 !important;
        border: 1px solid #969696 !important;
        color: var(--rfx-white) !important;
        opacity: 0.65;
      }
    }
  }
  &.btn-secondary {
    background: var(--rfx-secondary) !important;
    border: 1px solid var(--rfx-border) !important;
    color: var(--rfx-default) !important;
    &:hover {
      background: var(--rfx-secondary-hover) !important;
      border: 1px solid var(--rfx-border) !important;
    }
    &:active,
    &:focus-visible {
      background: var(--rfx-secondary-active) !important;
      border: 1px solid var(--rfx-border) !important;
      box-shadow: 0px 0px 0px 4px #007ea2b2 !important;
    }
    &:disabled {
      background: #ffffff00 !important;
      border: 1px solid var(--rfx-border) !important;
      color: var(--rfx-disabled-link) !important;
      opacity: 0.65;
    }
  }
  &.btn-danger {
    background: var(--rfx-danger) !important;
    border: 1px solid var(--rfx-danger) !important;
    color: var(--rfx-white) !important;
    &:hover {
      background: var(--rfx-danger-hover) !important;
      border: 1px solid var(--rfx-danger) !important;
    }
    &:active,
    &:focus-visible {
      background: var(--rfx-danger-active) !important;
      border: 1px solid var(--rfx-danger) !important;
      box-shadow: 0px 0px 0px 4px #dc354580 !important;
    }
    &:disabled {
      background: #ffffff00 !important;
      border: 1px solid var(--rfx-danger) !important;
      color: var(--rfx-danger) !important;
      opacity: 0.65;
    }
  }
  &.h-47 {
    height: 47px;
  }
  &.border-radius-2 {
    border-radius: 2px;
  }
  &.font-14 {
    font-size: 14px;
  }
}
.dropdown-toggle.custom-icon::after {
  border: 0;
  margin-left: 0;
}
