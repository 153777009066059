$roboto-font-path: "/assets/fonts/roboto" !default;
$font-fallbacks: Arial, sans-serif;

@font-face {
  font-family: "Roboto Regular";
  font-style: normal;
  font-weight: 400;
  src: url("#{$roboto-font-path}/roboto-regular.woff2") format("woff2"),
    url("#{$roboto-font-path}/roboto-regular.woff") format("woff"),
    url("#{$roboto-font-path}/roboto-regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: url("#{$roboto-font-path}/roboto-bold.woff2") format("woff2"),
    url("#{$roboto-font-path}/roboto-bold.woff") format("woff"),
    url("#{$roboto-font-path}/roboto-bold.ttf") format("truetype");
}
